<template>
  <div
    class="interface-facture"
    :class="{ 'interface-simulation-facture': computedRouterLinkFacture }"
  >
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GAPCFDF')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                @click:clear="handleResetDate('date_debut')"
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleDateTimePicker(date_debut, 'date_debut')"
              :allowed-dates="disabledStartDate"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('GAPCFDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                @click:clear="handleResetDate('date_fin')"
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleDateTimePicker(date_fin, 'date_fin')"
              :allowed-dates="disabledEndDate"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div
          v-if="getLoadingAppelPaiementCommerciaux"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          class="ml-2"
          v-if="getLoadingAppelPaiementCommerciaux || isLoading"
          indeterminate
          color="#704ad1"
        ></v-progress-circular>

        <div class="error-message ml-2 mr-2">
          <div v-if="getErrorAppelPaiement">
            <ul v-if="Array.isArray(getErrorAppelPaiement)" class="mb-0">
              <li v-for="(e, index) in getErrorAppelPaiement" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ getErrorAppelPaiement }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            v-if="checkPermission('GAPTHREC')"
            :hiddenContent="true"
          />
        </div>
        <!-- MAIL COMMERCIAL -->
        <mail-commercial
          @eventMailSendRefreshTable="handleFilter"
          :dataToUse="computedCheckDossier"
          v-if="
            checkPermission('GAPTHARF') &&
              computedCheckDossier &&
              computedCheckDossier.length
          "
        />
        <!-- LOCK AP -->
        <lock-ap
          :dataToUse="computedCheckRowsLock"
          v-if="
            computedCheckRowsLock &&
              computedCheckRowsLock.length &&
              computedCheckRowsLock.length == computedCheckRows.length &&
              isSuperAdminGetter
          "
        />
        <!--UPDATE MULTIPLE COLUMN APPEL PAIEMENT-->
        <update-multiple-column-appel-paiement
          v-if="
            checkPermission('GAPTHMMCSRCRAC') &&
              computedCheckRowsUpdate &&
              computedCheckRowsUpdate.length
          "
          :dataToUse="computedCheckRowsUpdate"
          :optionsSelectColumnSousRegie="computedGetSettingAllFilialesTh"
          :optionsSelectColumnCommercial="computedGetSettingAllParticulierTh"
          :optionsSelectStatut="computedListStatut"
          :generateFunction="updateColumnsCommercialMultiple"
          @reloadData="reloadData"
          :updateAll="computedCheckAllRowsUpdate"
        />

        <!-- FILTER -->
        <v-tooltip
          bottom
          color="#311B92"
          v-if="
            checkPermission('GAPCFI') ||
              checkPermission('GAPCFL') ||
              checkPermission('GAPCFAAP') ||
              checkPermission('GAPCFRE') ||
              checkPermission('GAPCFCO') ||
              checkPermission('GAPRACAAP') ||
              checkPermission('GAPCFSC')
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- INSTALLATEUR -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPCFI')"
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="isLocalStorageInstallateur"
                  :items="[...computedFilterInstallateur]"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Installateur"
                  required
                  return-object
                  item-text="text"
                  dense
                  class="mb-1"
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucun installateur trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageInstallateur.length - 0 }}
                      installateur(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- LOTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPCFL')"
                  v-model="isLocalStorageLots"
                  :items="computedFilterLots"
                  item-text="text"
                  item-value="value"
                  color="#704ad1"
                  item-color="#704ad1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Lot"
                  class="mb-1"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun lot trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageLots.length - 0 }} lot(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- Appel à paiement -->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('GAPCFAAP')"
                  v-model="isLocalStorageAppelPaiement"
                  :items="computedFilterPayment"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  color="#704ad1"
                  item-color="#704ad1"
                  hide-details
                  class="mb-4"
                  label="Appel à paiement"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
                <!-- Régie -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPCFRE')"
                  v-model="isLocalStorageRegie"
                  :items="[...computedGetSettingRegiesTh]"
                  color="#704ad1"
                  item-color="#704ad1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Régie"
                  required
                  class="mb-1"
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun régie trouvé"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageRegie.length - 0 }} régie(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- COMMERCIAL -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPCFCO')"
                  v-model="isLocalStorageAgentCommercial"
                  :items="[...computedGetSettingFilialesTh]"
                  color="#704ad1"
                  item-color="#704ad1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Commercial"
                  return-object
                  required
                  dense
                  class="mb-1"
                  item-text="full_name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun commercial trouvé"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageAgentCommercial.length - 0 }}
                      commercial(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!--RESTE A CHARGE-->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('GAPRACAAP')"
                  v-model="isLocalStorageResteCharge"
                  :items="computedFilterResteChage"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Reste à charge"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
                <!-- Statut Appel à paiement -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('GAPCFSC')"
                  v-model="isLocalStorageStatutAppelCommercial"
                  :items="listStatut"
                  item-text="full_name"
                  item-value="id"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Statut Appel à paiement"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageStatutAppelCommercial.length - 0 }}
                      Statut(s)
                    </span>
                  </template>
                </v-autocomplete>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-simulation">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >

          <span v-if="payment_call != null" class="item-filter-style"
            >Appel à paiement : {{ payment_call }}</span
          >
          <span
            v-if="
              commercial_reste_a_charge !== 'tous' &&
                commercial_reste_a_charge !== null
            "
            class="item-filter-style"
            >Reste à charge :
            {{ commercial_reste_a_charge | filterCommercialResteACharge }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- MASTER FILIALE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="installateur.length"
          class="space-table-filter-appel-paiement-fac"
        >
          <span class="title-block-chip-filter">Installateur : </span>
          <v-sheet elevation="2" class="py-0 px-1 mr-3">
            <v-chip-group mandatory>
              <v-chip
                :key="s.text"
                v-for="s in installateur"
                close
                @click:close="
                  remove(
                    s,
                    'installateur',
                    'handleFilter',
                    'isLocalStorageInstallateur'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- LOTS -->
        <v-col
          :cols="computedLengthFilter"
          v-if="lots.length"
          class="space-table-filter-appel-paiement-fac"
        >
          <span class="title-block-chip-filter ml-3">Lots : </span>
          <v-sheet elevation="2" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.text"
                v-for="v in lots"
                close
                @click:close="
                  remove(v, 'lots', 'handleFilter', 'isLocalStorageLots')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- REGIE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="regie.length"
          class="space-table-filter-appel-paiement-fac"
        >
          <span class="title-block-chip-filter">Régie : </span>
          <v-sheet elevation="2" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a"
                v-for="a in regie"
                close
                @click:close="
                  remove(a, 'regie', 'handleFilter', 'isLocalStorageRegie')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- Statut Appel à paiement  -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut_appel_commercial.length"
          class="space-table-filter-appel-paiement-fac"
        >
          <span class="title-block-chip-filter"
            >Statut Appel à paiement :
          </span>
          <v-sheet elevation="2" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="s.full_name"
                v-for="s in statut_appel_commercial"
                close
                @click:close="
                  remove(
                    s,
                    'statut_appel_commercial',
                    'handleFilter',
                    'isLocalStorageStatutAppelCommercial'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ s.full_name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- AGENT COMMERCIAL -->
        <v-col
          :cols="computedLengthFilter"
          v-if="agent_commercial.length"
          class="space-table-filter-appel-paiement-fac"
        >
          <span class="title-block-chip-filter">Commercial : </span>
          <v-sheet elevation="2" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.full_name"
                v-for="a in agent_commercial"
                close
                @click:close="
                  remove(
                    a,
                    'agent_commercial',
                    'handleFilter',
                    'isLocalStorageAgentCommercial'
                  )
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.full_name }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE SIMULATION -->
      <div
        class="groupe-content-appel-paiement"
        :class="{
          'padding-table':
            isLocalStorageAgentCommercial.length ||
            isLocalStorageStatutAppelCommercial.length ||
            isLocalStorageInstallateur.length ||
            isLocalStorageLots.length ||
            isLocalStorageRegie.length
        }"
      >
        <div class="btn-color-appel">
          <div>
            <div
              class="dot-vert-big"
              title="Statut Appel à paiement A payer"
            ></div>
            <hr class="ligne" />
            <div class="dot-red-big" title="Statut Appel à paiement Payé"></div>
            <hr class="ligne" />
          </div>
        </div>
        <div class="table-appel">
          <v-data-table
            v-model="selectedTable"
            :headers="computedFields"
            :items="getAppelPaiementCommerciaux"
            class="table-appel-paiement"
            :class="{
              'height-filter-chips-table':
                returnTableChips && !returnTableFiltre,
              'height-filter-table': returnTableFiltre && !returnTableChips,
              'height-filter-tous': returnTableChips && returnTableFiltre
            }"
            hide-default-footer
            :calculate-widths="false"
            :fixed-header="true"
            disable-sort
            :items-per-page="per_page"
            item-key="id"
            :item-class="rowClass"
            :disable-filtering="true"
            show-expand
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            hide-action
            :no-data-text="
              getLoadingAppelPaiementCommerciaux || isLoading
                ? 'Chargement en cours ...'
                : `Il n'y a aucun enregistrement à afficher`
            "
          >
            <!-- HEADERS TABLE -->
            <template v-slot:[`header.check_all`]="{ header }">
              <td class="custom-cell sticky-header-1">
                <span v-if="header.value != 'check_all'">
                  {{ header.label }}</span
                >
                <div v-else>
                  <v-checkbox
                    class="checked-global"
                    v-model="checkAll"
                    @change="checkAllFunction"
                    color="#704ad1"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
            </template>
            <!-- BODY TABLE -->
            <template v-slot:[`item.check_all`]="{ item }">
              <td class="custom-cell sticky-header-1">
                <v-checkbox
                  class="checked-groupe"
                  v-model="item.check"
                  @change="checkAllVerif(item)"
                  color="#704ad1"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </td>
            </template>
            <!--ROWS DETAILS-->
            <template v-slot:[`item.data-table-expand`]="{ item }">
              <td class="custom-cell sticky-header-2">
                <button class="btn btn-anomalie" @click="getDataForRow(item)">
                  <font-awesome-icon
                    class="icon-plus"
                    icon="plus-square"
                    color="#704ad1"
                    title="Afficher les simulations"
                    v-if="item.detailsShowing == false"
                  />
                  <font-awesome-icon
                    class="icon-plus"
                    icon="minus-square"
                    color="#704ad1"
                    title="Masquer les simulations"
                    v-if="item.detailsShowing == true"
                  />
                </button>
              </td>
            </template>
            <template v-slot:[`item.numero_dossier`]="{ item }">
              <td class="custom-cell sticky-header-3">
                {{ item.numero_dossier ? item.numero_dossier : '-' }}
              </td>
            </template>
            <template v-slot:[`item.installeur`]="{ item }">
              <td class="custom-cell">
                {{ item.installeur ? item.installeur : '-' }}
              </td>
            </template>
            <template v-slot:[`item.nom1`]="{ item }">
              <td class="custom-cell">
                {{ item.nom1 ? item.nom1 : '-' }}
              </td>
            </template>
            <template v-slot:[`item.prenom1`]="{ item }">
              <td class="custom-cell">
                {{ item.prenom ? item.prenom : '-' }}
              </td>
            </template>
            <template v-slot:[`item.regie`]="{ item }">
              <td class="custom-cell">
                {{ item.project_regie ? item.project_regie : '-' }}
              </td>
            </template>
            <template v-slot:[`item.date_depot_dossier`]="{ item }">
              <td class="custom-cell">
                {{ item.date_depot_dossier | formateDate }}
              </td>
            </template>
            <template v-slot:[`item.statut`]="{ item }">
              <td class="custom-cell">
                {{ item.statut ? item.statut : '-' }}
              </td>
            </template>
            <template v-slot:[`item.sous_regie`]="{ item }">
              <td class="custom-cell">
                <EditableInput
                  :editable="
                    checkPermission('GAPTHMCSR') &&
                      item.payment_call != 'oui' &&
                      item.has_proforma == false
                  "
                  champName="sous_regie"
                  editableType="selectInput"
                  :optionsSelect="computedGetSettingAllFilialesTh"
                  :item="item"
                  :value="item.sous_regie"
                  :updateFunction="updateColumnsCommercial"
                  label="full_name"
                  :positionSelect="true"
                  :showIconEdit="true"
                />
              </td>
            </template>
            <template v-slot:[`item.commercial_reste_a_charge`]="{ item }">
              <td class="custom-cell">
                <EditableInput
                  :editable="
                    checkPermission('GAPTHMCCRAC') &&
                      item.payment_call != 'oui' &&
                      item.has_proforma == false
                  "
                  champName="commercial_reste_a_charge"
                  :item="item"
                  :value="item.commercial_reste_a_charge"
                  type="number"
                  editableType="input"
                  :updateFunction="updateColumnsCommercial"
                  :defaultColor="true"
                />
              </td>
            </template>

            <template v-slot:[`item.agent_commercial_terrain`]="{ item }">
              <td class="custom-cell">
                <EditableInput
                  :editable="
                    checkPermission('GAPTHMCACT') &&
                      item.payment_call != 'oui' &&
                      item.has_proforma == false
                  "
                  champName="agent_commercial_terrain"
                  editableType="selectInput"
                  :optionsSelect="computedGetSettingFilialesTh"
                  :item="item"
                  :value="item.agent_commercial_terrain"
                  :updateFunction="updateColumnsCommercial"
                  label="full_name"
                  :positionSelect="true"
                  :showIconEdit="true"
                />
              </td>
            </template>
            <template v-slot:[`item.piece_jointe`]="{ item }">
              <td class="custom-cell">
                <div class="popover-piece-jointe">
                  <v-menu
                    right
                    offset-x
                    v-show="
                      item.appel_facturation && item.appel_facturation.length
                    "
                    v-model="item.popover"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="block-icon-table-facture"
                        v-on="on"
                        v-bind="attrs"
                      >
                        <font-awesome-icon icon="info-circle" class="" />
                      </div>
                    </template>
                    <v-card>
                      <v-card-text class="body-card center-text">
                        <v-data-table
                          :headers="[
                            { value: 'piece', text: 'Piéce' },
                            { value: 'date', text: 'Date appel à apiement' },
                            { value: 'responsable', text: 'Crée par' }
                          ]"
                          :items="item.appel_facturation"
                          hide-default-footer
                          hide-action
                          disable-filtering
                          disable-sort
                          disable-pagination
                          class="table-dialog"
                          item-class="text-center"
                        >
                          <template v-slot:[`item.piece`]="{ item }">
                            <div class="d-flex">
                              <div
                                class="block-icon-table-facture text-center"
                                @click="showAppelPaiement(item)"
                                title="Afficher Appel à facturation"
                              >
                                <font-awesome-icon icon="eye" />
                              </div>
                              <div
                                class="block-icon-table-facture text-center ml-1 mr-1"
                                @click="handleDownloadPiece(item)"
                                title="Télécharger Appel à la facturation"
                              >
                                <font-awesome-icon
                                  icon="arrow-alt-circle-down"
                                />
                              </div>
                            </div>
                            <!-- <span class="text-center">
                          
                          </span> -->
                          </template>
                          <template v-slot:[`item.date`]="{ item }">
                            <span class="text-center">{{
                              item.created_at
                            }}</span>
                          </template>
                          <template v-slot:[`item.responsable`]="{ item }">
                            <span class="text-center">{{
                              item.responsable_payment_call_name
                            }}</span>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                  <span class="ml-2">
                    {{
                      item.appel_facturation.length
                        ? item.appel_facturation.length
                        : '-'
                    }}
                  </span>
                </div>
              </td>
            </template>
            <template v-slot:[`item.statut_appel_commercial`]="{ item }">
              <td class="custom-cell">
                <EditableInput
                  :editable="checkPermission('GAPTHMCSAP')"
                  champName="statut_appel_commercial"
                  editableType="selectInput"
                  :optionsSelect="computedListStatut"
                  :item="item"
                  :value="item.statut_appel_commercial"
                  :updateFunction="updateColumnsCommercial"
                  label="full_name"
                  :positionSelect="true"
                  :showIconEdit="true"
                />
              </td>
            </template>
            <template v-slot:[`item.lock`]="{ item }">
              <td class="custom-cell sticky-header-end">
                <template v-if="item.payment_call == 'oui'">
                  <div
                    class="block-icon-table-facture ml-2"
                    @click.prevent.stop="changeLockAction(item)"
                    :disabled="item.loadingLock"
                    title="Annuler l'appel à paiement"
                  >
                    <font-awesome-icon
                      icon="check"
                      :class="{ hidden: item.loadingLock }"
                    />
                    <v-progress-circular
                      v-if="item.loadingLock"
                      class="ml-1 mr-1"
                      indeterminate
                      width="2"
                      size="10"
                      color="#704ad1"
                    ></v-progress-circular>
                  </div>
                </template>

                <div v-else>-</div>
              </td>
            </template>
            <template v-slot:[`item.ttc`]="{ item }">
              <td class="custom-cell">
                <template v-if="item.total">{{ item.ttc }} €</template>
                <column-frais-simulation
                  v-else
                  :showCoef="false"
                  :item="item"
                  column="ttc"
                  columnCoef="ttc"
                  :updateFunction="updateCoefFraisSimulation"
                  :editable="false"
                  :showBlock="true"
                  :uniteMontant="item.facture == false ? '' : '€'"
                />
              </td>
            </template>
            <template v-slot:[`item.coef`]="{ item }">
              <td class="custom-cell">
                <template v-if="item.total">-</template>
                <column-frais-simulation
                  v-else
                  :showCoef="true"
                  :item="item"
                  column="coef"
                  columnCoef="coef"
                  :updateFunction="updateCoefFraisSimulation"
                  :editable="checkPermission('SFTHMC')"
                  :showBlock="false"
                  :uniteMontant="item.coef_type"
                />
              </td>
            </template>
            <template v-slot:[`item.num`]="{ item }">
              <td class="custom-cell flex-div">
                {{ item.num ? item.num : '-' }}
                <custom-iframe
                  v-if="!item.total"
                  :getDataPDF="getDataPDFSimulation"
                  :data="item"
                />
              </td>
            </template>
            <template #expanded-item="{ headers , item }">
              <td
                class="td-expanded"
                :colspan="headers.length"
                v-if="item.detailsShowing == true"
              >
                <template
                  v-if="
                    item.loaderFacture &&
                      item &&
                      item.factures &&
                      item.factures.length === 0
                  "
                >
                  <circles-to-rhombuses-spinner
                    :animation-duration="1500"
                    :size="50"
                    class="loading-details-frais"
                  />
                </template>
                <v-simple-table v-else>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center text-dark"></th>
                        <th class="text-center text-dark">
                          Numéro Facture
                        </th>
                        <th class="text-center text-dark">
                          Statut
                        </th>
                        <th class="text-center text-dark">
                          Vendeur
                        </th>
                        <th class="text-center text-dark">
                          Client
                        </th>
                        <th class="text-center text-dark">Coefficient</th>
                        <th class="text-center text-dark">
                          HT
                        </th>
                        <th class="text-center text-dark">
                          TTC
                        </th>
                        <th class="text-center text-dark">
                          TVA
                        </th>
                        <th class="text-center text-dark">
                          Object
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(simulation, index) in item.factures"
                        :key="'tr' + index"
                      >
                        <td class="text-center">
                          -
                        </td>
                        <td class="text-center">
                          <v-row>
                            <v-col>
                              {{ simulation.num ? simulation.num : '-' }}
                            </v-col>
                            <v-col>
                              <custom-iframe
                                :getDataPDF="getDataPDFSimulationCommerciaux"
                                :data="simulation"
                              />
                            </v-col>
                          </v-row>
                        </td>
                        <td class="text-center">
                          {{ simulation.statue }}
                        </td>
                        <td class="text-center">
                          {{ simulation.vendeur }}
                        </td>
                        <td class="text-center">
                          {{ simulation.client }}
                        </td>
                        <td class="text-center">{{ simulation.coef }}</td>
                        <td class="text-center">{{ simulation.ht }} €</td>
                        <td class="text-center">{{ simulation.ttc }} €</td>
                        <td class="text-center">{{ simulation.tva }} €</td>
                        <td class="text-center">
                          {{ simulation.objet }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="per_page"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetX: true
          }"
          color="#704ad1"
          item-color="#704ad1"
        ></v-select>
      </div>
    </div>
    <v-dialog
      v-model="showAppelFacturation"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Afficher Appel à facturation</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleAppelFacturation('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card  mt-2">
          <div v-html="dataToVu" class="div-content"></div>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'Simulation-Facture-Frais',
  data() {
    return {
      isLoading: false,
      showAppelFacturation: false,
      selectedTable: [],
      menu: false,
      singleExpand: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      dataToVu: null,
      payment_call: null,
      commercial_reste_a_charge: 'tous',
      date_debut: null,
      date_fin: null,
      lots: [],
      installateur: [],
      statut_appel_commercial: [],
      agent_commercial: [],
      regie: [],
      expanded: [],
      payment: true,
      orderBy: 'id',
      order: 'DESC',
      page: 1,
      per_page: 20,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      showCoef: false,
      checkAll: false,
      filterConfirmation: null,
      listStatut: [
        {
          id: null,
          full_name: 'Aucun statut'
        },
        {
          id: 'Créé',
          full_name: 'Créé'
        },
        { id: 'A payer', full_name: 'A payer' },
        { id: 'Payé', full_name: 'Payée' }
      ],
      searchValue: null,
      isLocalStorageStatutAppelCommercial: [],
      isLocalStorageLots: [],
      isLocalStorageAgentCommercial: [],
      isLocalStorageInstallateur: [],
      isLocalStorageRegie: [],
      isLocalStorageAppelPaiement: null,
      isLocalStorageResteCharge: null
    };
  },
  computed: {
    ...mapGetters([
      'getProFilials',
      'checkPermission',
      'getAppelPaiementCommerciaux',
      'getLoadingAppelPaiementCommerciaux',
      'getErrorAppelPaiement',
      'getSettingFilialesTh',
      'getTotalRowsAppelPaiement',
      'getLoadingSettingFilialeTh',
      'filterInstalleurCommercial',
      'filterLotsCommercial',
      'loadingFiltreCommerciaux',
      'getListRegieForCommercialpage',
      'getLoaderListRegieForCommercialpage',
      'isSuperAdminGetter',
      'formateGeneraleDateGetters',
      'computedRouterLinkFacture',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.statut_appel_commercial,
        this.agent_commercial,
        this.regie,
        this.installateur,
        this.lots
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue != null ||
        this.payment_call !== null ||
        (this.commercial_reste_a_charge !== 'tous' &&
          this.commercial_reste_a_charge !== null)
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.agent_commercial.length ||
        this.isLocalStorageLots.length ||
        this.regie.length ||
        this.installateur.length ||
        this.lots.length
      ) {
        return true;
      }
      return false;
    },
    computedFilterInstallateur() {
      return this.filterInstalleurCommercial.map(item => {
        return {
          text: item,
          value: item
        };
      });
    },
    computedFilterLots() {
      return this.filterLotsCommercial.map(item => {
        return {
          text: item,
          value: item
        };
      });
    },
    computedFilterPayment() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Oui',
          value: 'oui'
        },
        {
          text: 'Non',
          value: 'non'
        }
      ];
    },
    computedFilterResteChage() {
      return [
        {
          text: 'Tous',
          value: 'tous'
        },
        {
          text: 'égale à 0',
          value: 'null'
        },
        {
          text: 'Supérieur à  0',
          value: 'notnull'
        }
      ];
    },
    computedGetSettingFilialesTh() {
      return this.getProFilials
        .filter(i => i.type == 'particulier')
        .map(item => {
          return {
            full_name: item.name,
            id: item.id
          };
        });
    },
    computedGetSettingAllFilialesTh() {
      return this.getProFilials.map(item => {
        return {
          full_name: item.name,
          id: item.id
        };
      });
    },
    computedGetSettingAllParticulierTh() {
      return this.getProFilials
        .filter(i => i.type == 'particulier')
        .map(item => {
          return {
            full_name: item.name,
            id: item.id
          };
        });
    },
    computedGetSettingRegiesTh() {
      return this.getListRegieForCommercialpage;
    },
    computedLineFilter() {
      if (
        this.date_debut ||
        this.date_fin ||
        this.searchValue ||
        this.payment_call ||
        this.commercial_reste_a_charge
      ) {
        return true;
      }
      return false;
    },
    computedListStatut() {
      return this.listStatut.filter(i => i.id != 'true');
    },
    computedCheckDossier() {
      return this.getAppelPaiementCommerciaux.filter(i => i.check == true);
    },
    totalPages() {
      if (this.getTotalRowsAppelPaiement) {
        return Math.ceil(this.getTotalRowsAppelPaiement / this.per_page);
      }
      return this.getTotalRowsAppelPaiement;
    },
    computedCheckRows() {
      let selected = [];
      this.getAppelPaiementCommerciaux.forEach(project => {
        if (project.check == true && project.payment_call != 'oui') {
          selected.push(project.id);
        }
      });
      return selected;
    },
    computedCheckRowsUpdate() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true
      );
    },
    computedCheckAllRowsUpdate() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true && item.payment_call == 'oui'
      ).length == 0
        ? true
        : false;
    },
    computedCheckRowsLock() {
      return this.getAppelPaiementCommerciaux.filter(
        item => item.check == true && item.payment_call == 'oui'
      );
    },

    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          width: '40px !important',
          class: 'sticky-header-1',
          isSortable: false
        },
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          width: '40px !important',
          class: 'sticky-header-2'
        },
        {
          value: 'numero_dossier',
          text: 'Numéro de dossier',
          show: this.checkPermission('GAPTHACND'),
          isSortable: false,
          class: 'sticky-header-3'
        },
        {
          value: 'installeur',
          text: 'Installateur',
          show: this.checkPermission('GAPTHACIN'),
          isSortable: false
        },
        {
          value: 'nom1',
          text: 'Nom',
          show: this.checkPermission('GAPTHACNOM'),
          isSortable: false
        },
        {
          value: 'prenom1',
          text: 'Prenom',
          show: this.checkPermission('GAPTHACPR'),
          isSortable: false
        },
        {
          value: 'regie',
          text: 'Régie',
          show: this.checkPermission('GAPTHACRG'),
          isSortable: false
        },
        {
          value: 'date_depot_dossier',
          text: 'Date dépôt de dossier',
          show: this.checkPermission('GAPTHACDD'),
          isSortable: false
        },
        {
          value: 'statut',
          text: 'Statut',
          show: this.checkPermission('GAPTHACST'),
          isSortable: false
        },
        {
          value: 'sous_regie',
          text: 'Sous régie',
          show: this.checkPermission('GAPTHACSR'),
          isSortable: false
        },
        {
          value: 'commercial_reste_a_charge',
          text: 'Commercial reste à charge',
          show: this.checkPermission('GAPTHACCRAC'),
          isSortable: false
        },
        {
          value: 'agent_commercial_terrain',
          text: 'Agent commercial terrain',
          show: this.checkPermission('GAPTHACACT'),
          isSortable: false
        },
        {
          value: 'piece_jointe',
          text: 'Pièce jointe',
          show: this.checkPermission('GAPTHFJ'),
          isSortable: false
        },
        {
          value: 'statut_appel_commercial',
          text: 'Statut Appel à paiement',
          show: this.checkPermission('GAPTHSAP'),
          isSortable: false
        },
        {
          value: 'lock',
          text: '',
          show: this.isSuperAdminGetter,
          width: '40px !important',
          class: 'sticky-header-end',
          isSortable: false
        }
      ];
      return fields.filter(item => item.show == true);
    }
  },
  filters: {
    filterCommercialResteACharge(value) {
      switch (value) {
        case 'tous':
          return 'Tous';
        case 'null':
          return 'égale à 0';
        case 'notnull':
          return 'Supérieur à 0';
        default:
          return value;
      }
    },
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    }
  },
  methods: {
    ...mapActions([
      'getSettingFilialeTh',
      'getFactureCommercial',
      'updateColumnsCommercial',
      'paymentCallCommercial',
      'getFilterCommerciaux',
      'updateColumnsCommercialMultiple',
      'fetchListRegieForCommercialpage',
      'changeLockAppelPaiementCommerciauxTh',
      'getFactureAndSimulationForCommercialAppelPayment',
      'getDataPDFSimulationCommerciaux',
      'getAllProFilial',
      'downloadPieceJointe'
    ]),
    handleAppelFacturation(action) {
      if (action == 'hide') {
        this.showAppelFacturation = false;
        this.dataToVu = null;
      } else {
        this.showAppelFacturation = true;
      }
    },
    showAppelPaiement(item) {
      this.dataToVu = item.body;
      this.handleAppelFacturation('show');
      item.popover = true;
    },
    handleDownloadPiece(piece) {
      this.downloadPieceJointe({ id: piece.id });
      piece.popover = true;
    },
    openPopover() {
      this.menu = true;
    },
    async getDataForRow(item) {
      item.detailsShowing = !item.detailsShowing;
      if (item.detailsShowing == true) {
        item.loaderFacture = true;
        await this.getFactureAndSimulationForCommercialAppelPayment(item);
        item.loaderFacture = false;
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    async changeLockAction(item) {
      item.loadingLock = true;
      await this.changeLockAppelPaiementCommerciauxTh([item]);
      item.loadingLock = false;
    },
    closePopover() {
      this.menu = false;
      this.lots = [];
      this.isLocalStorageLots = [];
      this.statut = [];
      this.installateur = [];
      this.isLocalStorageInstallateur = [];
      this.agent_commercial = [];
      this.isLocalStorageAgentCommercial = [];
      this.statut_appel_commercial = [];
      this.isLocalStorageStatutAppelCommercial = [];
      this.regie = [];
      this.isLocalStorageRegie = [];
      this.commercial_reste_a_charge = 'tous';
      this.isLocalStorageResteCharge = 'tous';
      this.payment_call = null;
      this.isLocalStorageAppelPaiement = null;
      this.handleFilter();
    },
    validerPopover() {
      //
      //SET FILTER FROM POPOVER
      //
      this.installateur = this.isLocalStorageInstallateur;
      this.lots = this.isLocalStorageLots;
      this.payment_call = this.isLocalStorageAppelPaiement;
      this.commercial_reste_a_charge = this.isLocalStorageResteCharge;
      this.regie = this.isLocalStorageRegie;
      this.statut_appel_commercial = this.isLocalStorageStatutAppelCommercial;
      this.agent_commercial = this.isLocalStorageAgentCommercial;
      //
      //END SET FILTER FROM POPOVER
      //
      this.handleFilter();
      this.menu = false;
    },
    handleLockFacture() {},
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    removeFamillyFilter() {
      this.filterFamilly = null;
      this.handleFilter();
    },
    removeAll(fct) {
      this[fct]();
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
      this.checkAll = false;
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
      this.checkAll = false;
    },
    async handleResetDate(name) {
      this[name] = null;
      this.setLocalStorageAppelPaiementCommercial();
      const response = await this.getFilterCommerciaux({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      if (response) {
        this.handleFilter();
      }
      this.handleFilter();
    },
    async handleDateTimePicker(value, name) {
      this[name] = value;
      this.setLocalStorageAppelPaiementCommercial();
      const response = await this.getFilterCommerciaux({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
      if (response) {
        this.handleFilter();
      }
      this.handleFilter();
    },
    disabledStartDate(date) {
      if (this.date_fin) {
        return (
          this.date_fin && moment(date, 'YYYY-MM-DD') <= new Date(this.date_fin)
        );
      }
      return true;
    },
    disabledEndDate(date) {
      if (this.date_debut) {
        return (
          this.date_debut &&
          moment(date, 'YYYY-MM-DD').add(1, 'days') > new Date(this.date_debut)
        );
      }
      return true;
    },
    handleChangeSupport() {
      this.handleFilter();
    },
    handleChangeFiliale() {
      this.refreshFilter(['refreshLots', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeFamille() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    handleChangeLots() {
      this.refreshFilter(['refreshClient', 'refreshMaster']);
      this.handleFilter();
    },
    changeSearchValue(value) {
      this.searchValue = value;
      this.handleFilter();
    },
    setLocalStorageAppelPaiementCommercial() {
      localStorage.setItem(
        'appel-paiement',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          search:
            this.searchValue && this.searchValue != ' '
              ? this.searchValue
              : null,
          lots: this.lots,
          installeurs: this.installateur,
          order: this.order,
          orderBy: this.orderBy,
          payment_call: this.payment_call,
          commercial_reste_a_charge: this.commercial_reste_a_charge,
          statut_appel_commercial: this.statut_appel_commercial,
          regie: this.regie,
          agent_commercial: this.agent_commercial
        })
      );
    },
    async handleFilter() {
      this.setLocalStorageAppelPaiementCommercial();
      this.$nextTick(async () => {
        this.getFactureCommercial({
          page: this.page,
          per_page: this.per_page,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          order: this.order,
          orderBy: this.orderBy,
          search: this.searchValue,
          payment: this.payment,
          lots: this.lots,
          installeurs: this.installateur,
          payment_call: this.payment_call,
          commercial_reste_a_charge: this.commercial_reste_a_charge,
          regie: this.regie,
          agent_commercial: this.agent_commercial,
          statut_appel_commercial: this.statut_appel_commercial
        });
      });
    },
    rowClass(item) {
      if (
        item?.payment_call == 'oui' &&
        item.statut_appel_commercial == 'Payé'
      ) {
        return 'ligneVert';
      }
      if (item?.payment_call == 'oui') {
        return 'ligneYellow';
      }
    },
    reloadData() {
      this.handleFilter();
    },
    checkAllFunction() {
      this.getAppelPaiementCommerciaux.map(item => {
        item.check = this.checkAll;
      });
    },
    checkAllVerif(item) {
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
    }
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    customIframe: () => import('@/views/component/customIframe'),
    mailCommercial: () => import('./component/mail/mailCommercial.vue'),
    updateMultipleColumnAppelPaiement: () =>
      import('./component/updateMultipleColumnAppelPaiement.vue'),
    lockAp: () => import('./lockApC.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue')
  },
  async mounted() {
    this.isLoading = true;
    this.getAllProFilial();
    await this.getSettingFilialeTh();
    await this.getFilterCommerciaux();
    this.fetchListRegieForCommercialpage();
    if (localStorage.getItem('appel-paiement')) {
      let localStoreValue = JSON.parse(localStorage.getItem('appel-paiement'));
      //
      //SET FILTER FROM LOCAL STORGE
      //
      this.date_debut = localStoreValue.date_debut
        ? localStoreValue.date_debut
        : null;
      this.date_fin = localStoreValue.date_fin
        ? localStoreValue.date_fin
        : null;
      this.searchValue = localStoreValue.searchValue
        ? localStoreValue.searchValue
        : null;
      this.installateur = localStoreValue.installeurs
        ? localStoreValue.installeurs
        : [];
      this.isLocalStorageInstallateur = this.installateur;
      this.lots = localStoreValue.lots ? localStoreValue.lots : [];
      this.isLocalStorageLots = this.lots;
      this.payment_call = localStoreValue.payment_call
        ? localStoreValue.payment_call
        : null;
      this.isLocalStorageAppelPaiement = this.payment_call;
      this.commercial_reste_a_charge = localStoreValue.commercial_reste_a_charge
        ? localStoreValue.commercial_reste_a_charge
        : 'tous';
      this.isLocalStorageResteCharge = this.commercial_reste_a_charge;
      this.statut_appel_commercial = localStoreValue.statut_appel_commercial
        ? localStoreValue.statut_appel_commercial
        : [];
      this.isLocalStorageStatutAppelCommercial = this.statut_appel_commercial;
      this.regie = localStoreValue.regie ? localStoreValue.regie : [];
      this.isLocalStorageRegie = this.regie;
      this.agent_commercial = localStoreValue.agent_commercial
        ? localStoreValue.agent_commercial
        : [];
      this.isLocalStorageAgentCommercial = this.agent_commercial;
    } else {
      this.setLocalStorageAppelPaiementCommercial();
    }
    this.handleFilter();
    this.isLoading = false;
  }
};
</script>
<style lang="scss">
.model-visual-piece-jointe {
  position: fixed;
  z-index: 1500;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #726d6dc4;
  height: 100%;
  /* overflow-y: auto; */
  bottom: 0px;
  right: 0px;
  padding: 20px;
  .div-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #8d8cb7;
      border-radius: 7px;
    }
  }
}
th.text-center.text-dark {
  border: thin solid #e0e0e0;
}
</style>
<style lang="scss" scoped>
.footer-style-table {
  padding: 5px 7px 0px 34px !important;
}
.dot-vert-big {
  background-color: rgba(234, 221, 31, 0.64) !important;
}

.dot-red-big {
  background-color: #c3d4c796 !important;
}
.space-table-filter-appel-paiement-fac {
  padding-top: 0px;
}
.groupe-content-appel-paiement {
  &.padding-table {
    padding-top: 19px;
  }
}
</style>
